@import '../colors.scss';

@keyframes spinner {
  to {transform: rotate(360deg);}
}

@-webkit-keyframes spinner {
  to {-webkit-transform: rotate(360deg);}
}

.spinner {
  position: relative;
  width: 100%;
  padding-bottom: 100%;

  & > div {
    position: absolute;
    content: 'Loading';
    box-sizing: border-box;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  &:not(:required) > div {
    content: '';
    border-radius: 50%;
    border: 2px solid $white;
    border-top-color: $primary;
    animation: spinner .6s linear infinite;
    -webkit-animation: spinner .6s linear infinite;

    @media (prefers-color-scheme: dark) {
      border-color: $black;
      border-top-color: $primary;
    }
  }
}
