@import '../colors.scss';

div.common-name-item {
  padding: .5em;
  margin: .2em .2em .2em 0;
  border: 1px solid $lighter;
  border-radius: .5em;
  display: flex;

  & div:nth-child(1) {
    margin-right: .5em;
    font-weight: bolder;
    text-transform: uppercase;
    color: $darkgrey;
  }

  & div:nth-child(2) {
    text-transform: capitalize;
    font-size: 1.1em;
  }

  @media (prefers-color-scheme: dark) {
    color: $lighter;

    & div:nth-child(1) {
      color: $white;
    }
  }
}
