@import '../colors.scss';

.loading-overlay {     
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lds-ring-container {
  display: inline-block;
  width: 64px;
  height: 64px;
  left: 50%;
}

.lds-ring-container div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid rgb(0, 0, 0);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(0, 0, 0) transparent transparent transparent;
  
  @media (prefers-color-scheme: dark) {
    border-color: $white transparent transparent transparent;
  }
}

.lds-ring-container div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring-container div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring-container div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring-container {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

