@import '../colors.scss';

.navbar-brand {
  display: inline-block;
  margin-top: -7px;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  margin-left: 10px;

  &::before, &::after {
    box-sizing: border-box;
  }
}

@font-face {
  font-family: 'Leckerli One';
  src: url('../LeckerliOne-Regular.ttf');
}

@font-face {
  font-family: 'Courier Prime';
  src: url('../CourierPrime-Bold.ttf');
}
