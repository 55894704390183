@import '../colors.scss';

.login-form {

  & .form-group {
    margin-top: 10px;
  }

  & .login-button-row {
    margin-top: 10px;
    box-sizing: border-box;
    width: 100%;
    display: flex;

    & > div {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-left: auto;
      box-sizing: border-box;
    }
  }

  & button {
    display: flex;
    background-color: $primary;
    color: $white;
    font-weight: bold;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    transition: 
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out, 
      border-color 0.15s ease-in-out, 
      box-shadow 0.15s ease-in-out;

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem transparentize($primary, .5);
    }

    &:hover {
      background-color: $darkgrey;
    }

  }
}

.credentials-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  & input {
    width: 100%;
  }
}

.form-control {
  box-sizing: border-box;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
