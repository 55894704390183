@import 'layout.scss';
@import 'colors.scss';

div.bird-search-result-card {
  display: grid;
  grid-template-columns: 5rem auto;
  grid-template-rows: 5rem;
  margin: .25rem;
  border-radius: .25rem;
  border: 1px solid $light-primary;

  @media (prefers-color-scheme: dark) {
    background-color: $black;
    border: 1px solid $primary;
  }

  & > div.circled-bird-picture {
    grid-row: 1;
    grid-column: 1;
  }

  & > div.body {
    grid-row: 1;
    grid-column: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    overflow: hidden;

    & .name {
      margin-left: .5rem;
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      color: $lightgrey;
      font-size: .75rem;
      overflow: hidden;

      & .bird-name {
        font-size: 1rem;
        font-weight: bolder;
        color: $darkgrey;
        
        @media (prefers-color-scheme: dark) {
          color: $white;
        }
      }
    }

    & a {
      margin-right: 1rem;
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      /* override styling set by android firefox browser */
      &:active {
        background-color: unset !important;
      }

      & svg {
        padding: .3rem;
        box-shadow: 0 0 1rem rgba($black, .1);
        box-sizing: border-box;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        fill: $primary;
        transition: background-color .1s, fill .1s, box-shadow .1s;

        &:active {
          box-shadow: 0 0 1rem rgba($primary, .5);
          background-color: $primary;
          fill: $white;
        }

        @media (prefers-color-scheme: dark) {
          background-color: black;
        }
      }
    }
  }

  @media only screen and (min-width : $break-small) {
    height: 7rem;
    grid-template-columns: 7rem auto;
    grid-template-rows: 7rem;
  }
}
