@import '../layout.scss';
@import '../colors.scss';

div.circled-bird-picture {
  position: relative;
  z-index: 0;
  height: 100%;

  &::before {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    content: '';
    border-radius: 50%;
    margin: 9.7%;
    box-shadow: inset -.25rem .5rem 1rem rgba($black, .15);

    @media (prefers-color-scheme: dark) {
      box-shadow: inset -.25rem .5rem 1rem rgba(black, .2);
    }
  }

  & img {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  & img:not([src$=".png"]) {
    border-radius: 50%;
    transform: scale(.8);
  }
}
