@import 'colors.scss';

div.clear-search-button {
  cursor: pointer;
  fill: $lighter;
  display: flex;
  justify-content: center;

  & svg {
    height: 100%;
  }

  &:hover {
    fill: $darkgrey;
  }
}
