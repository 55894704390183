@import 'layout.scss';
@import 'colors.scss';

$disabled-button-color: $lighter;

button.search-button {
  white-space: nowrap;
  font-size: inherit;
  font-weight: 500;
  border: 1px solid transparent;
  padding: 0;
  border: 0;
  transition: 
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out,
      max-width 0.1s ease-in,
      padding 0.1s ease-in;
  color: $white;
  flex-shrink: 0;
  overflow: hidden;
  background-color: $primary;
  
  &:hover {
    color: $white;
    background-color: $darkgrey;
  }

  &:disabled {
    cursor: default;
    background-color: $disabled-button-color;
    color: darken($disabled-button-color, 15);
  }
}

@media #{$tablet-landscape-up} {
  button.search-button {
    transition: max-width 0s, padding 0s;
    border-radius: 0 0.5rem 0.5rem 0;
    padding: 0 16px;
  }
}

@media (prefers-color-scheme: dark) {
  $disabled-button-color: lighten($darkgrey, 15);

  button.search-button {
    &:disabled {
      background-color: $disabled-button-color;
      color: darken($disabled-button-color, 15);
    }
  }
}
