@import 'colors.scss';
@import 'layout.scss';

.picture-cover-container {
  @extend %side-margins;
  @extend %common-main-margin-top;
  display: flex;

  & .bird-picture {
    margin-right: 10px;

    & > img {
      width: 5rem;
    }
  }
}
