@import '../colors.scss';

div.sighting-form-link-button {

  & span {
    white-space: nowrap;
  }

  & svg {
    width: 2em;
    fill: $primary;
    margin-right: .5em;

    &:hover {
      fill: $primary;
    }
  }
}
