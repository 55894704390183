@import 'colors.scss';
@import '../layout.scss';

.navbar-constant {
  width: 100%;
  display: grid;
  background-color: $light-primary;
  grid-template-columns: 150px auto 75px;
  grid-template-rows: 64px;
  grid-template-areas:
    "main search button";
    
    @media (prefers-color-scheme: dark) {
      background-color: $black;
    }

  @media #{$tablet-landscape-up} {
    grid: 64px / 3fr 7fr 75px;
    grid-template-areas:
      'main search button';
  }

  @media #{$desktop-up} {
    grid: 64px / 1fr 750px 1fr;
    padding-right: 0;
    grid-template-areas:
      "main search button";
  }
}

.navbar-constant .search-form {
  grid-area: search;
  align-self: center;

  @media #{$tablet-landscape-up} {
    margin-right: 7px;
  }

  @media #{$desktop-up} {
    margin-right: 0px;
  }
}

.navbar-constant .menu {
  grid-area: menu;
  align-self: center;
  transition: position .5s;
}

.navbar-constant .menu-button {
  grid-area: button;
  align-self: center;
  justify-self: end;
  padding: 7px;
}
