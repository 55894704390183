@import '../colors.scss';

$height: 7rem;

div.sighting-list-item {
  height: $height;
  overflow: hidden;
  display: grid;
  grid-template-columns: $height 1fr;
  grid-template-rows: 2rem 5rem;
  color: $black;

  & a {
    grid-row-start: 1;
    grid-row-end: span 2;
  }

  & .bird-thumbnail {
    height: 100%;
    width: 100%;
  }

  & .details {
    margin-left: 10px;

  }

  & div.name {
    justify-content: baseline;
    align-items: baseline;

    & div.bird-name {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 1.1rem;
      font-weight: bold;
      border-bottom: 1px solid $lighter;
    }

    & .binomial-name {
      font-size: .8rem;
      color: $lightgrey;
    }
  }

  @media (prefers-color-scheme: dark) {
    color: $white;
  }
}

.sighting-list-item-body {
  display: grid;
  grid: auto auto / auto auto;
}

.sighting-list-item-body-placeholder {
  border: 1px solid #EEE;
}
