@import 'colors.scss';

div.advanced-search-section {
  background-color: $white;
}

@media (prefers-color-scheme: dark) {
  div.advanced-search-section {
    background-color: $darkgrey;
  }
}
