@import '../layout.scss';

.feed-card {

  &:not(:first-of-type) {
    margin-top: 5px;
  }

  &.sighting {
    display: grid;
    grid-template-columns: 7em 1fr;
    grid-template-rows: 7em;
    grid-column-gap: 5px;

    @media only screen and (min-width : $break-small) {
      padding: 0;
    }

    & .circled-bird-picture {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    & hr {
      margin: unset;
      border: 0;
      border-top: 1px solid rgba(0,0,0,.1);
    }

    & .bird-name {
      font-weight: bold;
      font-size: 1.3em;
    }
  }
}
