@import '../colors.scss';

.bird-name-card {
  flex-direction: column;
  color: $black;
  margin-bottom: 0;

  & h1.bird-page-name {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 25px;
    z-index: 1;

    /* => md */
    @media (min-width: 768px) {
      font-size: 35px;
    }
  }

  & > p.binomial {
    margin-top: 0px;
  }

  @media (prefers-color-scheme: dark) {
    color: $white;
  }
}
