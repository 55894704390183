$black: #262626;
$darkgrey: #4C535A;
$lightgrey: #8F98A1;
$lighter: #CED4DA;
$white: #FCFCFC;
$primary: #943e61;
$light-primary: #f9eee5;
$danger: #dc2a02;
$success: #59CD90;
$color3: #3FA7D6;
$color4: #FAC05E;
$color5: #F79D84;
