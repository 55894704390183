@import 'layout.scss';
@import 'colors.scss';

div.email-registration-page {
  @extend %common-main-margin-top;
  display: flex;
  flex-direction: column;

  & h1.page-heading {
    @extend %common-page-heading-positioning;
  }

  & p {
    @extend %side-margins;
  }

  & form {
    @extend %side-margins;
    align-self: stretch;
    display: flex;
    flex-direction: column;

    & button {
      margin-top: 10px;
    }
  
    & input {
      margin-top: 10px;
      width: 100%;
    }

    & button {
      align-self: flex-end;
      background-color: $primary;
      color: $white;
      font-weight: bold;
      border: 1px solid transparent;
      border-radius: 0.25rem;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      transition: 
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out, 
        border-color 0.15s ease-in-out, 
        box-shadow 0.15s ease-in-out;
    
      &:focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem transparentize($primary, .5);
      }
    
      &:hover {
        background-color: $darkgrey;
      }
    }
  }

  & > hr {
    margin-left: 0;
    margin-right: 0;
    align-self: stretch;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
  }

  & > a {
    @extend %side-margins;
    align-self: flex-start;
  }
}
