@import '../colors.scss';

div.sighting-success {
  
  & .success-text {
    color: $success;
  }

  & > * > * {
    margin: auto;
  }
}
