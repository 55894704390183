@import '../layout.scss';
@import '../colors.scss';

div.common-names-section {
  padding: 0 .5em;
  
  & h2 {
    font-size: 1.5em;
  }

  & div.names-cluster {
    display: flex;
    flex-wrap: wrap;
  }

  @media (prefers-color-scheme: dark) {
    & h2 {
      color: $white;
    }
  }
}
