@import '../colors.scss';

div.form-group {
  width: 100%;
  display: flex;
  flex-direction: column;

  & > input {
    box-sizing: border-box;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    &:read-only {
      background-color: $lighter;
      cursor: default;
    }
  }

  & .is-invalid {
    border-color: red;
  }

  & .is-valid {
    border-color: green;
  }

  & .valid-feedback, & .invalid-feedback {
    display:none;
  }
  
  & .is-invalid ~ .invalid-feedback {
    color: red;
    display: block;
  }
  
  & .is-valid ~ .valid-feedback {
    color: green;
    display: block;
  }

  & .is-valid {
    &:focus {
      box-shadow: 0 0 0 0.2rem transparentize($success, 0.6)!important;
    }
  }
}
