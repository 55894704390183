@import '../colors.scss';
@import '../layout.scss';

nav.navbar-menu {
  background-color: $white;
  transition: max-height .3s;
  padding-left: 15px;
  width: 100%;
  height: auto;
  max-height: 0px;
  overflow-y: hidden;

  @media (prefers-color-scheme: dark) {
    background-color: $black;
    border-bottom: 1px $lightgrey solid;
  }

  &.collapsed {
    visibility: hidden;
  }

  &.expanding, &.expanded, &.collapsing {
    display: block;
  }

  & > * {
    display: block;
    padding: .5rem 1rem;
    cursor: pointer;
    padding-right: 0;
    padding-left: 0;
    color: rgba(0,0,0,.5);
  
    &:hover {
      color: rgba(0,0,0,.7);
    }    
  
    @media (prefers-color-scheme: dark) {
      color: $lighter;
  
      &:hover {
        color: white;
      }
    }
  }
}
