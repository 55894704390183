main {
  overflow: hidden;
  display: grid;
  grid-template-areas: 'content';
  -webkit-perspective: 500px;
  perspective: 500px;

  & > div {
    grid-area: content;
    max-width: 100vw;
    position: relative;
  }
}
