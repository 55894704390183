@import 'colors.scss';

div.navbar {
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 .5rem 1rem transparentize(black, .95);

  @media (prefers-color-scheme: dark) {
    background-color: $black;
    box-shadow: 0 .5rem 1rem transparentize(black, .9);
  }
}
