@import '../colors.scss';
@import '../layout.scss';

footer {
  overflow: hidden;
  color: #6c757d;
  background-color: $black;
  padding: 1.1rem;
  box-sizing: border-box;
  white-space: nowrap;

  @media (prefers-color-scheme: dark) {
    background-color: $darkgrey;
  }

  & .languages {
    display: flex;
    flex-direction: row;

    & * {
      margin: 0 .5rem;
      line-height: 1.5rem;
      font-size: inherit;
      padding: 0;
      border: none;
      background: none;
      color: $lightgrey;

      &:focus {
        outline: none;
      }

      &:hover {
        color: $primary;
      }

      &:active {
        color: $white;
      }

      &.selected {
        color: $darkgrey;
      }

      @media (prefers-color-scheme: dark) {
        color: $lighter;

        &.selected {
          color: $black;
        }
      }
    }
  }
}
