@import '../colors.scss';
@import 'layout.scss';

h1.page-heading {
  @extend %side-margins;
  margin-bottom: .5rem;
  margin-top: 0px;
  box-sizing: border-box;
  line-height: 1.2;
  font-weight: bolder;
  font-size: 2.5rem;

  @media (prefers-color-scheme: dark) {
    color: $white;
  }
}
