@import '../colors.scss';
@import '../layout.scss';

.login-page {
  @extend %common-main-margin-top;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  & h1.page-heading {
    @extend %common-page-heading-positioning;
  }

  & .login-form {
    @extend %side-margins;
    max-width: 600px;
    align-content: center;
  }

  & a {
    font-weight: 400;
    font-size: 16px;

    &:hover {
      text-decoration: none;
    }
  }
  
  & .credentials-recovery-link {
    margin-top: 20px;
    text-align: center;
  }

  & .separator {
    border-top-style: solid;
    border-top-color: #CED4DA;
    border-top-width: 1px;
    margin: 40px 0 20px 0;
  }
}

.new-account-section {
  text-align: center;
}

.bold-question {
  font-size: 18px;
  font-weight: 900;
}
