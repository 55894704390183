@import '../colors.scss';

$card-height: 468px;
$card-width: 336px;
$picture-height: $card-height/2;
$header-backdrop-blur: 2px;

.sighting-card {
  height: $card-height;
  width: $card-width;
  position: relative;
  border-radius: 1rem;
  background-color: black;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 1px 1px 8px transparentize($color: black, $amount: .7);


  & > .background {
    grid-row: 1;
    overflow: hidden;
    position: absolute;
    background-size: cover;
    background-repeat: repeat-y;
    background-position: top;
    height: $picture-height + ($header-backdrop-blur * 2);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: -($header-backdrop-blur * 2);
    filter: blur($header-backdrop-blur);
    -webkit-filter: blur($header-backdrop-blur);

    &.body-background {
      top: $picture-height;
      transform: scaleY(-1);
      background-size: cover;
      height: $picture-height + ($header-backdrop-blur * 16);
      margin: -($header-backdrop-blur * 8);
      filter: blur($header-backdrop-blur * 4);
      -webkit-filter: blur($header-backdrop-blur * 4);
    }
    
    &.gradient {
      top: $picture-height;
      background-size: cover;
      background: rgb(125,32,20);
background: linear-gradient(0deg, rgba(125,32,20,1) 0%, rgba(0,212,255,0) 100%);
    }
  }

  & > .name {
    border: 2px solid white;
    position: absolute;
    top: $card-height/2-10px;
    left: 10px;
    right: 10px;
    text-align: center;
    z-index: 2;
    font-size: 1.15em;
    font-weight: bold;
    background-color: transparentize(darken( $primary, 20), .20);
    border: 4px solid $primary;
    height: 25px;
    border-radius: 10px;
    border-top-left-radius: 15px 30px;
    border-top-right-radius: 15px 30px;
    color: white;
  }

  & > .date {
    position: absolute;
    bottom: 12px;
    left: 60px;
    right: 60px;
    font-weight: bold;
    text-align: center;
    color: white;
    background-color: transparentize( #000000, 0.6);
    border-radius: 5px;
    border-top: 1px transparentize( white, 0.4) solid;
    border-bottom: 1px transparentize( white, 0.4) solid;
    padding: 3px;
  }

  & > .body {
    position: absolute;
    padding: 5px;
    top: $picture-height + 20px;
    left: 14px;
    right: 14px;
    bottom: 50px;
    border: 3px solid $primary;
    background-color: transparentize( white, .25);
    border-radius: 3px;
    font-weight: bold;
    border-bottom-left-radius: 50px 10px;
    border-bottom-right-radius: 50px 10px;
    -webkit-box-shadow: inset 4px 0px 24px -10px rgba(0,0,0,0.21);
    -moz-box-shadow: inset 4px 0px 24px -10px rgba(0,0,0,0.21);
    box-shadow: inset 4px 0px 24px -10px rgba(0,0,0,0.21);
  }

  & > .picture {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: $picture-height;
    border-style: none;
    background-color: black;
    overflow: hidden;
    padding: 0;

    & > .middle-front {
      grid-row: 1;
      grid-column: 2;
      z-index: 1;
      margin-top: 50px;
      height: $picture-height - 50px;
    
      & img {
        height: 100%; 
        width: 100%; 
        object-fit: contain;
      }
    }
  }
}
