@import '../colors.scss';
@import 'layout.scss';

%input {
  box-sizing: border-box;
  display: block;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  font-family: Arial, Helvetica, sans-serif;

  &:disabled {
    background-color: #e9ecef;
    opacity: 1;
  }
}

div.log-bird-section {
  @extend %side-margins;
  @extend %common-main-margin-top;

  & .form-group {
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    font-size: .875rem;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }

  & form {
    & div.date-group {
      display: grid;
      grid: 1fr / 100px 1fr;

      & .date-input {
        @extend %input;
        width: 100%;
      } 
    }

    & div.time-group {
      margin-top: 10px;
      margin-bottom: 10px;
      display: grid;
      grid: 1fr / 100px 40px 1fr;

      & .time-checkbox-container {
        background-color: $lighter;
        border-top-left-radius: .5rem;
        border-bottom-left-radius: .5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      & .time-input {
        @extend %input;
        width: 100%;
      }
    }

    &>div.submit-button-group {
      margin-top: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

.log-bird-section button[type=submit] {
  display: flex;
  background-color: $primary;
  color: $white;
  font-weight: bold;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  transition: 
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, 
    border-color 0.15s ease-in-out, 
    box-shadow 0.15s ease-in-out;
  
  &[disabled] {
    background-color: $lighter;
  }
}

.location-expansion {
  height: 0px;
  transition: height 0.3s ease-in-out;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border: 1px solid $lighter;
  overflow: hidden;
  text-align: center;
  font-size: 50px;
}

.location-section {

  &.collapsed .location-expansion {
    border-width: 0;
  }

  &.expanding {
    & .toggle-button {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-bottom-width: 0px;
    }

    & .location-expansion {
      height: 100px;
    }
  }

  &.expanded {
    & .toggle-button {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-bottom-width: 0px;
    }

    & .location-expansion {
      height: 100px;
    }
  }

  &.collapsing2 {
    & .toggle-button {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-bottom-width: 0px;
    }
  }
  
  & .loaded-icon {
    color: $success;
  }
}
