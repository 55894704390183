@import '../colors.scss';
@import 'layout.scss';

div.navbar-account {
  padding-top: 0px;
  display: none;

  & a {
    color: $black;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  @media only screen and (min-width : $break-small) {
    display: flex;
  }

  @media (prefers-color-scheme: dark) {
    & a {
      color: $white;
    }
  }
}
