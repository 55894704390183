@import '../colors.scss';

.new-password-form-group {
  width: 100%;

  & input {
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $black;
    background-color: white;
    background-clip: padding-box;
    border: 1px solid $lighter;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &.is-valid {
      border-color: $success;
      &:focus {
        box-shadow: 0 0 0 0.2rem transparentize($success, 0.6)!important;
      }
    }

    &.is-valid ~ .valid-feedback {
      display: block;
    }

    &.is-invalid ~ .invalid-feedback {
      display: block;
    }
  }
}
