@import 'layout.scss';
@import 'colors.scss';

form.search-form {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  font-size: 1.25rem;
  position: relative;

  & div.advanced-search-section {
    box-sizing: border-box;
    align-self: stretch;
    max-height: 0;
    overflow: hidden;
    transition: 
        max-height .1s ease-in-out, 
        padding-top .1s ease-in-out, 
        padding-bottom .1s ease-in-out;
    padding: 0;
    padding-left: .5rem;

    @media #{$tablet-landscape-up} {
      border-radius: .5rem;
      position: absolute;
      top: 62px;
      left: 0;
      right: 0;
      opacity: 1;
      transition: 
          max-height .1s ease-in-out, 
          padding-top .1s ease-in-out, 
          padding-bottom .1s ease-in-out,
          top .1s ease-in-out,
          opacity .1s ease-in-out,
          box-shadow .1s ease-in-out;

      &.dirty:not(.active) {
        display: block;
        height: 1rem;
        border-top: 1px $lighter solid;
        padding: .5rem;
        top: 34px;
        left: 0;
        right: 0;

        & * {
          opacity: 0;
        }
      }

      &.active {
        box-shadow: 0 .5rem 1rem transparentize(black, .85);
      }
    }

    &.dirty {
      border-bottom: 2px $primary solid;
    }

    &.active {
      display: block;
      max-height: 100px;
      border-top: 1px $lighter solid;
      padding: .5rem;
    }
  }
}

div.simple-search-section {
  display: flex;
  max-width: 100vw;
  font-size: 1.25rem;

  & div.text-input {
    position: relative;
    width: 100%;
    align-items: center;
    border: 1px solid $lighter;
    border-width: 1px;

    @media #{$tablet-landscape-up} {
      border-radius: 0.5rem 0 0 0.5rem;
      border-left-width: 1px;
    }

    & input.search-input {
      width: 100%;
      min-width: 0px;
    }
  }

  & button.search-button {
    z-index: 1;
    max-width: 0;
  
    @media #{$tablet-landscape-up} {
      max-width: unset;
    }
  
    &.expanded {
      max-width: 100px;
      padding: 0 16px;
    }
  }
}
