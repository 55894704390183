$break-small: 768px;
$break-large: 1224px;
$footer-height: 60px;
$tablet-landscape-up: "only screen and (min-width : 768px)";
$desktop-up: "only screen and (min-width : 1224px)";

%side-margins {
  margin-left: 1rem;
  margin-right: 1rem;
}

%common-main-margin-top {
  margin-top: 1rem;
}

%common-page-heading-positioning {
  @extend %side-margins;
  margin-bottom: .5rem;
  margin-top: 0;
}
