@import 'colors.scss';
@import 'layout.scss';

div.splash-page {
  @extend %side-margins;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: $white;
  min-height: 100vh;
  padding-bottom: $footer-height;

  @media (prefers-color-scheme: dark) {
    background-color: $black;
  }

  & > *:nth-child(1) {
    box-sizing: border-box;
    font-size: 2.5em;
    padding-top: 1.5rem;
    height: 100px;
  }

  & > div.login-section {
    text-align: center;
    height: 100px;

    @media (prefers-color-scheme: dark) {
      color: $white;
    }
  }

  & > form.search {

    & input.search-input {
      border-radius: 0.5rem;
    }
  }
}
