@import 'colors.scss';
@import 'layout.scss';

.bird-result-container {
  @extend %common-main-margin-top;
  position: relative;

  & .bird-search-result-info {
    @extend %side-margins;
    color: $lightgrey;
    margin-bottom: 10px;
    overflow: hidden;
  }

  & > div.items-container {
    display: grid;
    grid-template-columns: 1fr;

    @media only screen and (min-width : $break-small) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (min-width : $break-large) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
