@import '../colors.scss';
@import '../layout.scss';

div.sightings-filter {
  @extend %side-margins;
  
  & button {
    color: $primary;
    box-sizing: content-box;
    background: none!important;
    display: inline;
    border: none;
    font-family: inherit;
    transform-origin: 0 0;
    perspective-origin: 0 0;
    cursor: pointer;

    &:focus {
      outline: none;
    }
    
    &:hover {
      color: $black;
    }
    
    &.selected {
      color: $darkgrey;
      cursor: unset;
    }
  }

  @media (prefers-color-scheme: dark) {

    & button:hover {
      color: $white;
    }
  }
}
