@import './layout.scss';
@import './colors.scss';

%navbar-pushed {
  padding-top: 64px;
}

:focus {
  outline:none;
}

::-moz-focus-inner {
  border:0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: .875rem;
  line-height: 1.5;

  @media (prefers-color-scheme: dark) {
    background-color: $black;
    color: $white;
  }
}

a {
  color: $primary;
  text-decoration: none;

  &:hover {
    color: $black;

    @media (prefers-color-scheme: dark) {
      color: $white;
    }
  }
}

button {
  cursor: pointer;
}

input:focus {
  border-color: $light-primary;
  box-shadow: 0 0 0 0.2rem rgba(238, 99, 82, 0.25);
  outline-color: transparent;
  outline-width: 0;
}

div#root {
  position: relative;
  min-height: 100vh;
  overflow: hidden;

  & div.navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }

  & main {
    @extend %navbar-pushed;
    padding-bottom: $footer-height + 40px;
    box-sizing: border-box;
    width: 100%;
    min-height: 100vh;
    -webkit-perspective: 500px;
    perspective: 500px;
  }
  
  & footer {
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
    height: $footer-height;
  }
}
